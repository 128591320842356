import { fetchModule } from "./actions";
import apiCaller from "../utils/api";

function fetchModuleMaster() {
  return dispatch => apiCaller.getRequest(
    "module_role",
    {},
    res => {
      dispatch(fetchModule(res.data));
    },
    err => {
      dispatch(fetchModule({}));
      return {};
    }
  );
}
export default fetchModuleMaster;