import React from "react";
import numeral from "numeral";
import moment from "moment";
import queryString from "query-string";
import { isNumber, isObject, isEmpty, isArray, isString } from "lodash";
import * as FORMAT from "../constants/format";
import { empty } from "rxjs";

export const numberFormat = (number, fomat) => {
  if (!isNumber(parseInt(number)) || number < 0 || isNaN(parseInt(number))) {
    return number;
  }

  return numeral(number).format(fomat || "0,0.00");
};

export const displayError = (error) => {
  if (!isEmpty(error)) {
    if (Array.isArray(error)) {
      return error.join("\n");
    }
    if (isObject(error)) {
      let msg = [];
      for (let prop in error) {
        msg.push(error[prop]);
        msg.join("\n");
      }
      return msg.join("\n");
    }
    return error;
  }
  return "";
};

export const generateOptions = (options) => {
  return options
    ? options.map((item, idx) => {
      return (
        <option key={idx} value={item.value}>
          {item.display_name}
        </option>
      );
    })
    : null;
};

export const objectToFormData = (obj) => {
  let formData = new FormData();

  for (let key in obj) {
    if (obj[key] || obj[key] === 0) {
      if (isArray(obj[key])) {
        obj[key].forEach((val, idx) => {
          formData.append(key + `[${idx}]`, val);
        })
      } else {
        formData.append(key, obj[key]);
      }
    } else {
      formData.append(key, "");
    }
  }
  return formData;
};

export const exportFile = (response) => {
  let filename = "export.csv";
  const disposition = response.headers["content-disposition"];

  if (disposition && disposition.indexOf("attachment") !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  const type = response.headers["content-type"];
  const blob = new Blob(["\ufeff" + response.data], { type: type, endings: "native" });
  const URL = window.URL || window.webkitURL;
  let a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.removeChild(a);
};

export const downloadExcel = (response, filename) => {
  const disposition = response.headers["content-disposition"];

  if (disposition && disposition.indexOf("attachment") !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }

  const type = response.headers["content-type"];
  const blob = new Blob([response.data], { type: type, endings: "native" });
  const URL = window.URL || window.webkitURL;
  let a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.removeChild(a);
};

export const downloadPdf = (response, filename) => {
  const type = response.headers["content-type"];
  const blob = new Blob([response.data], { type: type, endings: "native" });
  const URL = window.URL || window.webkitURL;
  let a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.removeChild(a);
};

export const convertDate = (date, format) => {
  if (!date) return "";
  if (!format) {
    format = FORMAT.DEFAULT_DATE
  }

  return moment(date).isValid()
    ? moment(date).format(format)
    : date;
};

export const checkAndConvertDate = (date, format) => {
  if (!date) return "";
  if (!format) {
    format = FORMAT.DEFAULT_DATE
  }

  return moment(date, "YYYY/M/D", true).isValid()
    ? moment(date).format(format)
    : date;
};

export const convertDateTime = (datetime, format) => {
  if (!datetime) return "";
  if (!format) {
    format = FORMAT.DEFAULT_DATE + " " + FORMAT.DEFAULT_TIME
  }

  return moment(datetime).isValid()
    ? moment(datetime).format(format)
    : datetime;
};

export const parseQueryParamsForSearch = (query, acceptedParams) => {
  const queryObject = queryString.parse(query);
  const searchParamsObject = {};
  acceptedParams.forEach((param) => {
    searchParamsObject[param] = queryObject.param || "";
  });
  return queryObject;
};

export const isFile = (file) => {
  return file instanceof File || (file && !isEmpty(file.file_path));
};

export const mergeNotifyData = (notify) => {
  let merged_notify =
    [
      {
        'mail_notify': 0,
        'linework_notify': 0,
      },
      {
        'mail_notify': 0,
        'linework_notify': 0,
      },
      {
        'mail_notify': 0,
        'linework_notify': 0,
      },
      {
        'mail_notify': 0,
        'linework_notify': 0,
      }
    ];

  merged_notify[0]['mail_notify'] =
    notify[0] && isEmpty(notify[0]['mail_notify']) ? notify[0]['mail_notify'] : 0;

  merged_notify[0]['linework_notify'] =
    notify[0] && isEmpty(notify[0]['linework_notify']) ? notify[0]['linework_notify'] : 0;

  merged_notify[1]['mail_notify'] =
    notify[1] && isEmpty(notify[1]['mail_notify']) ? notify[1]['mail_notify'] : 0;

  merged_notify[1]['linework_notify'] =
    notify[1] && isEmpty(notify[1]['linework_notify']) ? notify[1]['linework_notify'] : 0;

  merged_notify[2]['mail_notify'] =
    notify[2] && isEmpty(notify[2]['mail_notify']) ? notify[2]['mail_notify'] : 0;

  merged_notify[2]['linework_notify'] =
    notify[2] && isEmpty(notify[2]['linework_notify']) ? notify[2]['linework_notify'] : 0;

  merged_notify[3]['mail_notify'] =
    notify[3] && isEmpty(notify[3]['mail_notify']) ? notify[3]['mail_notify'] : 0;

  merged_notify[3]['linework_notify'] =
    notify[3] && isEmpty(notify[3]['linework_notify']) ? notify[3]['linework_notify'] : 0;

  return merged_notify;
};

export const checkPermission = (module_master, role, router_role) => {
  if (!isEmpty(module_master)) {
    let data = module_master.filter(function (n) {
      return n.role_identifier === role && n.module_identifier === router_role;
    });
    return isEmpty(data) ? false : true;
  }
  return true;
};

export const convertKyuujitsu = (holiday) => {
  if (isEmpty(holiday)) return 0;
  holiday = JSON.parse(holiday);
  return holiday.length;
};

export const removeNullElementObj = (obj) => {
  if (isEmpty(obj))
    return {}

  for (var prop in obj) {
    if (obj[prop] === null || obj[prop] === undefined || obj[prop] === "") {
      delete obj[prop];
    }
  }
  return obj;
}

export const toHalfWidthTrimSpace = (Str) => {
  var value = "";
  for (var i = 0; i < Str.length; i++) {
    var c = Str.charCodeAt(i);
    if (c === 12288 || c === 32) {
      continue;
    }
    if (c > 65280 && c < 65375) {
      value += String.fromCharCode(c - 65248);
      continue;
    }
    value += String.fromCharCode(c);
  }
  return value;
};

export const deleteEmptyItemArray = (values) => {
  const array = values.filter((item) => {
    return item !== "delete" && item !== "" && item !== undefined
  });
  return array;
}

export const convertSearchParams = (values) => {
  Object.keys(values).forEach(function (key) {
    if (typeof (values[key]) === "string") {
      var value = "";
      var str = values[key];
      let y = "";
      let boolean = false;
      for (var i = 0; i < str.length; i++) {
        var c = str.charCodeAt(i);
        if (c === 12288) {
          if (boolean === true) {
            y += String.fromCharCode(c);
            continue;
          }
        } else {
          boolean = true;
          y += String.fromCharCode(c);
          value = y;
        }
      }
      values[key] = value;
    }
  });
}

export const customStyles = {
  control: base => ({
    ...base,
    background: "#f4f7fa",
    height: "43px",
    padding: "0px 10px",
    color: "#ced4da"
  }),
  singleValue: base => ({
    ...base,
    color: "#495057"
  }),
};

export const customDisabledStyles = {
  control: base => ({
    ...base,
    background: "#e9ecef",
    height: "43px",
    padding: "0px 10px",
    border: "1px solid #ced4da",
  }),
  singleValue: base => ({
    ...base,
    color: "#495057"
  }),
};

export const detectEnvironment = (status) => {
  let style = "";
  switch(status) {
    case "dev":
      style = "開発環境";
      break;
    case "stag":
      style = "ステージング環境";
      break;
    default:
      style = "";
  }
  return style;
};

export const convertMessageImportCsv = (obj) => {

  if (isString(obj)) {
    return obj;
  }

  if (isEmpty(obj))
    return "";

  let message = "CSVファイル入力エラー：</br>";
  let row = 0;

  Object.values(obj).forEach((params) => {

    // if(!isEmpty(params) && !isEmpty(params.row) && !isEmpty(params.errors)) {

      if(row != params.row) {
        message += "・ 行→「"+ params.row +"」エラ:</br>";
        row = params.row;
      }

      params.errors.forEach((param) => {
        message +=  "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" + param + "</br>";
      });

      message = message.replace(params.row + ".", "");
    // }
  });

  return message;
}

export const  openNewWindow = (url, width, height) => {
  var leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
  var topPosition = (window.screen.height / 2) - ((height / 2) + 50);

  window.open(url, "_blank",
    "status=no,height=" + height + ",width=" + width + ",resizable=yes,left="
    + leftPosition + ",top=" + topPosition + ",screenX=" + leftPosition + ",screenY="
    + topPosition + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no");
}