import { fetchUserProfile } from "./actions";
import apiCaller from "../utils/api";

function fetchLoggedInUser() {
  return dispatch => apiCaller.getRequest(
    "user/profile",
    {},
    res => {
      dispatch(fetchUserProfile(res.data));
    },
    err => {
      dispatch(fetchUserProfile({}));
      return {};
    }
  );
}
export default fetchLoggedInUser;
