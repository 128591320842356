import { fetchRole } from "./actions";
import apiCaller from "../utils/api";

function fetchRoleMaster() {
  return dispatch => apiCaller.getRequest(
    "role",
    {},
    res => {
      dispatch(fetchRole(res.data));
    },
    err => {
      dispatch(fetchRole({}));
      return {};
    }
  );
}
export default fetchRoleMaster;