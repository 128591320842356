import React, { Component } from "react";

class ScrollTopButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      intervalId: 0
    };
  }

  componentDidMount() {
    var scrollComponent = this;
    document.addEventListener("scroll", function (e) {
      scrollComponent.toggleVisibility();
    });
  }

  toggleVisibility() {
    this.setState({
      is_visible: window.pageYOffset > 100
    });
  }

  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }

  scrollToTop() {
    let intervalId = setInterval(
      this.scrollStep.bind(this),
      this.props.delayInMs
    );
    this.setState({ intervalId: intervalId });
  }

  render() {
    const { is_visible } = this.state;
    return (
      <React.Fragment>
        {is_visible && (<button
          title="Back to top"
          className="scroll"
          onClick={() => {
            this.scrollToTop();
          }}
        >
          <i className="feather icon-arrow-up" />
        </button>)}
      </React.Fragment>
    );
  }
}

export default ScrollTopButton;