export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';

export const FETCH_KUBUN_PENDING = 'FETCH_KUBUN_PENDING';
export const FETCH_KUBUN_SUCCESS = 'FETCH_KUBUN_SUCCESS';
export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const FETCH_MODULE_MASTER = 'FETCH_MODULE_MASTER';
export const FETCH_ROLE_MASTER = 'FETCH_ROLE_MASTER';

export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export function fetchKubunPending() {
  return {
    type: FETCH_KUBUN_PENDING
  }
}

export function fetchKubunSuccess(kubun) {
  return {
    type: FETCH_KUBUN_SUCCESS,
    kubun: kubun
  }
}

export function fetchUserProfile(user) {
  return {
    type: FETCH_USER_PROFILE,
    profile: user
  }
}

export function fetchRole(role_master) {
  return {
    type: FETCH_ROLE_MASTER,
    role_master: role_master
  }
}

export function fetchModule(module_master) {
  return {
    type: FETCH_MODULE_MASTER,
    module_master: module_master
  }
}