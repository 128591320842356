import config from "./../config";

export const initialState = {
  isOpen: [], //for active default menu
  isTrigger: [], //for active default menu, set blank for horizontal
  ...config,
  isFullScreen: false, // static can't change,
  kubun: {},
  role_master: {},
  module_master: {},
  profile: {},
  pageTitle: '',
  pending: false,
  error: null
};