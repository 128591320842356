import { fetchKubunSuccess } from "./actions";
import apiCaller from "../utils/api";

function fetchKubun() {
  return dispatch => apiCaller.getRequest(
    "kubun",
    {},
    res => {
      dispatch(fetchKubunSuccess(res.data));
    },
    err => {
      dispatch(fetchKubunSuccess({}));
      return {};
    }
  );
}
export default fetchKubun;
