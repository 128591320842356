import React from 'react';

const Login = React.lazy(() => import('./App/pages/Login'));
const ResetPassword = React.lazy(() => import('./App/pages/auth/ResetPassword'));
const ResetPasswordComplete = React.lazy(() => import('./App/pages/auth/ResetPasswordComplete'));
const CreateNewPassword = React.lazy(() => import('./App/pages/auth/CreateNewPassword'));
const NewPasswordComplete = React.lazy(() => import('./App/pages/auth/NewPasswordComplete'));

const route = [
    { path: '/login', exact: true, name: 'ログイン', component: Login },
    { path: '/password/reset', exact: true, name: 'パスワード再発行', component: ResetPassword },
    { path: '/password/resetComplete', exact: true, name: 'パスワード再発行', component: ResetPasswordComplete },
    { path: '/createNewPassword', exact: true, name: 'パスワード更新', component: CreateNewPassword },
    { path: '/newPasswordComplete', exact: true, name: 'パスワード更新', component: NewPasswordComplete },
];

export default route;