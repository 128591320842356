import axios from "axios";
import { exportFile, downloadPdf, downloadExcel } from "../helpers/common";
import Cookies from 'universal-cookie';

let SERVER_BASE_URL = `${process.env.REACT_APP_BASE_URL}:8888/api/`;

const initHttp = (optionalHeaders) => {
  let headers = {
    "Content-Type": "application/json",
    ...optionalHeaders,
  };
  const authToken = localStorage.getItem("authToken");
  if (authToken) {
    headers["Authorization"] = `Bearer ${authToken}`;
  }

  let http = axios.create({
    headers,
  });
  http.interceptors.response.use(undefined, (err) => {
    // if error is 401
    const authToken = localStorage.getItem("authToken");
    if (authToken && err.response.status === 401) {
      localStorage.removeItem("authToken");
      const cookies = new Cookies();
      cookies.set('url', window.location.pathname + window.location.search)
      window.location.href = "/";
    }
    return Promise.reject(err);
  });
  return http;
};

const postRequest = (path, data, resolve, reject, headers) => {
  const requestUrl = SERVER_BASE_URL + path;
  const http = initHttp(headers);
  http
    .post(requestUrl, data)
    .then((res) => {
      resolve(res.data.response);
    })
    .catch((error) => {
      reject(error);
    });
};

const getRequest = (path, data, resolve, reject, headers) => {
  const requestUrl = SERVER_BASE_URL + path;
  const http = initHttp(headers);
  http
    .get(requestUrl, data)
    .then((res) => {
      resolve(res.data.response);
    })
    .catch((error) => {
      reject(error);
    });
};

const putRequest = (path, data, resolve, reject, headers) => {
  const requestUrl = SERVER_BASE_URL + path;
  const http = initHttp(headers);
  http
    .put(requestUrl, data)
    .then((res) => {
      resolve(res.data.response);
    })
    .catch((error) => {
      reject(error);
    });
};

const patchRequest = (path, data, resolve, reject, headers) => {
  const requestUrl = SERVER_BASE_URL + path;
  const http = initHttp(headers);
  http
    .patch(requestUrl, data)
    .then((res) => {
      resolve(res.data.response);
    })
    .catch((error) => {
      reject(error.response);
    });
};

const deleteRequest = (path, data, resolve, reject, headers) => {
  const requestUrl = SERVER_BASE_URL + path;
  const http = initHttp(headers);
  http
    .delete(requestUrl, data)
    .then((res) => {
      resolve(res.data.response);
    })
    .catch((error) => {
      reject(error.response);
    });
};

const download = (path, data, reject) => {
  const requestUrl = SERVER_BASE_URL + path;
  const http = initHttp({});
  http
    .get(requestUrl, data)
    .then((res) => {
      exportFile(res);
    })
    .catch((error) => {
      reject(error.response);
    });
};

const downloadFilePDF = (path, data, resolve, reject) => {
  const requestUrl = SERVER_BASE_URL + path;

  let headers = {
    'Accept': 'application/pdf'
  };
  const authToken = localStorage.getItem("authToken");
  if (authToken) {
    headers["Authorization"] = `Bearer ${authToken}`;
  }

  let http = axios.create({
    headers,
  });
  const config = {
    responseType: 'arraybuffer',
    params: {
      file_name: data.params.file_name,
      file_path: data.params.file_path,
    }
  };

  http
    .get(requestUrl, config)
    .then((res) => {
      downloadPdf(res, data.params.file_name);
      resolve(res);
    })
    .catch((error) => {
      reject(error.response);
    });
};

const downloadImage = (path, data, resolve, reject) => {
  const requestUrl = SERVER_BASE_URL + path;

  let headers = {
    'Accept': 'application/jpeg'
  };
  const authToken = localStorage.getItem("authToken");
  if (authToken) {
    headers["Authorization"] = `Bearer ${authToken}`;
  }

  let http = axios.create({
    headers,
  });
  const config = {
    responseType: 'arraybuffer',
    params: {
      file_name: data.params.file_name,
      file_path: data.params.file_path,
    }
  };

  http
    .get(requestUrl, config)
    .then((res) => {
      downloadPdf(res, data.params.file_name);
      resolve(res);
    })
    .catch((error) => {
      reject(error.response);
    });
};

const downloadZipFile = (path, data, reject) => {
  const requestUrl = SERVER_BASE_URL + path;

  let headers = {
    'Accept': 'application/zip'
  };
  const authToken = localStorage.getItem("authToken");
  if (authToken) {
    headers["Authorization"] = `Bearer ${authToken}`;
  }

  let http = axios.create({
    headers,
  });
  const config = {
    responseType: 'arraybuffer',
    params: {
      ...data
    }
  };

  http
    .get(requestUrl, config)
    .then((res) => {
      downloadPdf(res, "document.zip");
    })
    .catch((error) => {
      reject(error.response);
    });
};

const downloadExelFile = (path, data, reject) => {
  const requestUrl = SERVER_BASE_URL + path;

  let headers = {
    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
  };
  const authToken = localStorage.getItem("authToken");
  if (authToken) {
    headers["Authorization"] = `Bearer ${authToken}`;
  }

  let http = axios.create({
    headers,
  });

  const config = {
    responseType: 'arraybuffer',
    params: data.params
  };

  http
    .get(requestUrl, config)
    .then((res) => {
      downloadExcel(res, "download.xlsx");
    })
    .catch((error) => {
      reject(error.response);
    });
};

export default {
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
  download,
  downloadFilePDF,
  downloadZipFile,
  downloadExelFile,
  downloadImage,
};