import React, { Component, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loadable from "react-loadable";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { isEmpty } from "lodash";
import Aux from "../hoc/_Aux";

import "../../node_modules/font-awesome/scss/font-awesome.scss";
import Loader from "./layout/Loader";
import ScrollToTop from "./layout/ScrollToTop";
import routes from "../route";
import ScrollTopButton from "./components/ScrollTopButton";
import PrivateRoute from "./components/PrivateRoute";
import { AuthContext } from "../context/auth";
import fetchKubunAction from "../store/fetchKubun";
import fetchModuleAction from "../store/fetchModuleMaster";
import fetchRoleAction from "../store/fetchRoleMaster";
import fetchLoggedInUser from "../store/fetchUserProfile";

const AdminLayout = Loadable({
  loader: () => import("./layout/AdminLayout"),
  loading: Loader,
});

class App extends Component {
  constructor(props) {
    super(props);
    const authToken = localStorage.getItem("authToken");
    this.state = {
      authToken,
    };
  }

  componentDidMount = () => {
    const { kubun, fetchKubun, fetchProfile, fetchRole, fetchModule } = this.props;

    if (isEmpty(kubun) && this.state.authToken) {
      fetchKubun();
      fetchProfile();
      fetchRole();
      fetchModule();
    }
  }

  setAuthToken = token => {
    if (token) {
      localStorage.setItem("authToken", token);
    } else {
      localStorage.removeItem("authToken");
    }
    this.setState({
      authToken: token,
    });
  };

  render() {
    const menu = routes.map((route, index) => {
      return route.component ? (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          name={route.name}
          render={(props) => <route.component {...props} />}
        />
      ) : null;
    });

    return (
      <Aux>
        <AuthContext.Provider
          value={{
            authToken: this.state.authToken,
            setAuthToken: this.setAuthToken,
          }}
        >
          <ScrollToTop>
            <Suspense fallback={<Loader />}>
              <Switch>
                {menu}
                <PrivateRoute path="/" component={AdminLayout} />
              </Switch>
            </Suspense>
          </ScrollToTop>
          <ScrollTopButton scrollStepInPx="50" delayInMs="16.66" />
        </AuthContext.Provider>
      </Aux>
    );
  }
}

const mapStateToProps = state => ({
  kubun: state.kubun
})

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchKubun: fetchKubunAction,
  fetchRole: fetchRoleAction,
  fetchModule: fetchModuleAction,
  fetchProfile: fetchLoggedInUser
}, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(App));